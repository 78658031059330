//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HubFilters',
  components: {},
  // Props useful when displayed as a widget
  props: {
    categories: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filter: '*',
      noScroll: true,
    }
  },
  computed: {
    noScrollClass() {
      return this.noScroll ? 'no-scroll' : ''
    },
  },
  watch: {
    filter(newVal) {
      this.$emit('select-filter', newVal)
      this.adjustFiltersScroll()
    },
  },
  mounted() {
    this.initFiltersScroll()
  },
  methods: {
    initFiltersScroll() {
      const screenWidth = window.innerWidth
      const sliderWidth = this.$refs.hubFilters.scrollWidth
      // Init carousel, only if length of filters is out of screen bounds
      if (sliderWidth > screenWidth) {
        this.noScroll = false
      }
    },
    adjustFiltersScroll() {
      const screenWidth = window.innerWidth
      const hubFiltersElmt = this.$refs.hubFilters
      const sliderWidth = hubFiltersElmt.scrollWidth
      const scrollLeftMax = sliderWidth - screenWidth

      if (!this.noScroll) {
        // Current active button of slider
        let activeButton = this.$refs['btn-category-' + this.filter]
        if (typeof activeButton.getBoundingClientRect !== 'function') {
          activeButton = activeButton[0]
        }
        const btnWidth = activeButton.getBoundingClientRect().width

        // Position of button inside slider
        const btnLeft =
          activeButton.getBoundingClientRect().x + hubFiltersElmt.scrollLeft

        // Find new theoretical xScrollSlider
        // The equation to solve is : screenWidth/2 - btnWidth/2 = - xScrollSlider + btnLeft
        // screenWidth = document.body.clientWidth rather than window.innerWidth, so scroll bar width is included
        let xScrollSlider =
          btnWidth / 2 - document.body.clientWidth / 2 + btnLeft

        // We don't want empty spaces between extremities of slider and screen bounds
        xScrollSlider = Math.max(0, Math.min(scrollLeftMax, xScrollSlider))

        // Scroll to that position
        hubFiltersElmt.scrollTo(xScrollSlider, 0)
      }
    },
    filterCheckedClass(categoryId) {
      return categoryId === this.filter ? 'is-checked' : ''
    },
  },
}
