//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Clipr from '../Clipr'
import HubHeader from './HubHeader'
import HubFilters from './HubFilters'

let Isotope
if (process.browser) {
  Isotope = require('isotope-layout')
}

export default {
  name: 'Hub',
  components: {
    HubFilters,
    HubHeader,
    Clipr,
  },
  // Props useful when displayed as a widget
  props: {
    cliprTestMode: {
      type: Boolean,
      default: false,
    },
    initialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      gridLayout: [6, 6, 12, 4, 8, 4, 6, 6, 8, 4, 4],
      gridClipsPerRow: [2, 0, 1, 3, 0, 0, 2, 0, 3, 0, 0],
      gridFinalLayout: [],
      isHeaderFixed: false,
      cliprIsotope: {},
      filter: '*',
      stories: [],
      displayStories: [],
      openStories: false,
      startShortCode: '',
    }
  },
  computed: {
    backgroundColorStyles() {
      return this.initialHub.color
        ? {
            background: '#' + this.initialHub.color,
          }
        : null
    },
    backgroundImgStyles() {
      return this.initialHub.background_img
        ? {
            backgroundImage: 'url("' + this.initialHub.background_img + '")',
          }
        : null
    },
    categories() {
      return this.initialHub.categories || null
    },
    color() {
      return this.initialHub.color || ''
    },
    description() {
      return this.initialHub.description || ''
    },
    externalUrl() {
      return this.initialHub.url || null
    },
    externalUrlTitle() {
      return this.initialHub.url_title || null
    },
    initialHub() {
      return this.initialData.hub
    },
    initialStories() {
      return this.initialData.stories
    },
    initialTags() {
      return this.initialData.integration
    },
    initialCliprStories() {
      // This avoids editing Hub gallery when injecting Ad stories in Stories component
      return this.initialData
    },
    logo() {
      return this.initialHub.logo || ''
    },
    title() {
      return this.initialHub.name || ''
    },
    hasContent() {
      return this.description || this.logo || this.name
    },
    watermark() {
      return (
        this.initialHub.watermark === true ||
        parseInt(this.initialHub.watermark) === 1
      )
    },
  },
  watch: {
    filter() {
      // Rebuild masonry grid
      this.rebuildMasonryGrid()
    },
  },
  mounted() {
    // Listen to scroll events
    window.addEventListener('scroll', () => this.updateHeaderClass())

    this.stories = this.sortStories(this.initialStories)
    this.$nextTick(() => {
      // Initialize isotope grid
      this.isotope()

      window.addEventListener('message', this.cliprCrossFrameMessage, false)
    })

    // http://landing2.clipr.local:3000/?clipr_env=dev

    // Local
    /* var CLIP_EMBEDDED_API_KEY="i7ym23stwr753717v4F5boffy4wefm";
    // var URL_CLIPR_ALT = "https://example.com";
    var domain="http://landing2.clipr.local:8888";
    var s=document.createElement("script");s.type="text/javascript";var d = new Date();var v = d.getYear()+"."+d.getMonth()+"."+d.getDate();s.src=domain+"/bundles/cnvpublicdisplay/js/clip-embedded.js?v="+v;var head=document.getElementsByTagName("head")[0];head.appendChild(s);
    setTimeout(() => {
      clipr_tryInitialization(0, "i7ym23stwr753717v4F5boffy4wefm"); console.log('init header')
    }, 2000) */

    // Depuis cluster direct

    /* var headerDiv = document.createElement('div');
    headerDiv.id = 'clipr_header_container';
    headerDiv.style.zIndex = 10000;
    headerDiv.style.position = 'absolute';
    headerDiv.style.top = 0;
    headerDiv.style.left = 0;
    document.body.appendChild(headerDiv);

    var CLIP_EMBEDDED_API_KEY="n9dcykvvbgit6x0ve8jfb9ca8fdq20";
    var domain="https://d28exisy2odk9a.cloudfront.net";
    // var domain="https://d18yi4qgzwuj5v.cloudfront.net"; // staging
    var s=document.createElement("script");s.type="text/javascript";var d = new Date();var v = d.getYear()+"."+d.getMonth()+"."+d.getDate();s.src=domain+"/bundles/cnvpublicdisplay/js/clip-embedded.min.js?v="+v;var head=document.getElementsByTagName("head")[0];head.appendChild(s);
    setTimeout(() => {
      clipr_tryInitialization(0); console.log('init header')
    }, 1000)
    */
  },
  methods: {
    openExternalUrl() {
      window.location = this.externalUrl
    },
    sortStories(initialStories) {
      // Sort stories by priority
      return initialStories && initialStories.length > 0
        ? initialStories.sort(function (a, b) {
            const contentA = parseInt(a.hub.priority)
            const contentB = parseInt(b.hub.priority)
            return contentA < contentB ? -1 : contentA > contentB ? 1 : 0
          })
        : []
    },
    categoryClass(categories) {
      const category =
        categories && categories.length > 0 ? categories[0] : null
      return category ? '_' + category.id + '_' : null
    },
    thumbnailStyles(thumbnail) {
      return thumbnail
        ? {
            backgroundImage: 'url("' + thumbnail + '")',
          }
        : null
    },
    gridItemSizeClass(index) {
      return this.gridFinalLayout.length <= index
        ? null
        : this.gridFinalLayout[index]
    },
    updateHeaderClass() {
      // Header mode depending on scroll position
      this.isHeaderFixed = window.scrollY > 50
    },
    cliprCrossFrameMessage(event) {
      const messageData = event.data
      if (
        messageData.key === 'USER_PRESSED_CLOSE_BUTTON' ||
        messageData.key === 'LAST_CLIP_STORY_ENDED'
      ) {
        this.openStories = false
        this.$nextTick(() => {
          // Wait a bit before resetting stories array, so no black screen
          setTimeout(() => (this.displayStories = []), 500)
        })
      }
    },
    onPreviewClickLaunchStory(shortCode) {
      this.startShortCode = shortCode
      this.displayStories = this.stories.filter((story) => {
        const categories = story.hub.categories
        const category =
          categories && categories.length > 0 ? categories[0].id : null
        return this.filter === '*' || category === this.filter
      })
      this.$nextTick(() => {
        this.openStories = true
      })
    },
    isotope() {
      // Init grid item width before initialize masonry grid to avoid strange transitions at launch
      this.rebuildMasonryGrid()

      // Without next tuick
      this.$nextTick(() => {
        this.cliprIsotope = new Isotope('.content__grid', {
          itemSelector: '.clip-preview-container',
          percentPosition: true,
          masonry: {
            itemSelector: '.clip-preview-container',
            columnWidth: '.grid-sizer',
            percentPosition: true,
          },
          filter: this.filter,
        })
      })
    },
    rebuildMasonryGrid() {
      const filterClass =
        this.filter !== '*'
          ? '.clip-preview-container._' + this.filter + '_'
          : '.clip-preview-container'

      // Previews that shou;d be displayed
      const allFilteredContainers = document.querySelectorAll(filterClass)
      const allFilteredContainersLength = allFilteredContainers.length
      let needRowAdjustement = false
      let clipsColOnLastRow = 0
      const newLayout = []

      const allContainers = document.querySelectorAll('.clip-preview-container')

      let idxVisibleContainer = 0
      allContainers.forEach((elmt) => {
        if (
          this.filter === '*' ||
          elmt.classList.contains('_' + this.filter + '_')
        ) {
          // Preview visible

          // Get column with from gridLayout
          const colIndex = idxVisibleContainer % this.gridLayout.length

          if (!needRowAdjustement) {
            // Do we have enough clips to fill the entire row ?
            const nbOfClipsOnRow = this.gridClipsPerRow[colIndex]

            // If we don't have enough clips to cpmplete the row
            if (
              idxVisibleContainer + nbOfClipsOnRow >
              allFilteredContainersLength
            ) {
              // Then edit nb of clips on last row
              needRowAdjustement = true
              clipsColOnLastRow =
                12 / (allFilteredContainersLength - idxVisibleContainer)
            }
          }

          // Adjust col if necessary
          const col =
            clipsColOnLastRow > 0
              ? clipsColOnLastRow
              : this.gridLayout[colIndex]

          // Apply to item
          const itemSize = 'grid-item--width-' + col / 2

          newLayout.push(itemSize)

          idxVisibleContainer++
        } else {
          // Preview hidden
          newLayout.push('')
        }
      })

      // Update grid item dynamic class
      this.gridFinalLayout = newLayout

      // Rebuild masonry
      if (
        this.cliprIsotope !== null &&
        typeof this.cliprIsotope.layout === 'function'
      ) {
        this.$nextTick(() => {
          this.cliprIsotope.layout()
        })
      }
    },
  },
}
