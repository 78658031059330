//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HubHeader',
  components: {},
  // Props useful when displayed as a widget
  props: {
    description: {
      type: String,
      default: '',
    },
    logo: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    logoStyles() {
      return this.logo
        ? {
            backgroundImage: 'url("' + this.logo + '")',
          }
        : null
    },
  },
  mounted() {},
  methods: {},
}
